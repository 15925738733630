<template>
  <v-card>
    <v-card-title class="align-start">
      <span>{{ statTitle }}</span>
      <v-spacer v-if="showBtnDots"></v-spacer>
      <v-fab-transition v-if="showBtnDots">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              x-small
              class="me-n3 mt-n2"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiHelp }}
              </v-icon>
            </v-btn>
          </template>
          <span>tooltip</span>
        </v-tooltip>
      </v-fab-transition>
    </v-card-title>

    <v-card-text>
      <!-- Chart -->
      <vue-apex-charts
        id="chartBar"
        :ref="chartBar"
        :options="computedChartOptions"
        :series="computedChartData"
        :height="chartHeight"
      ></vue-apex-charts>

      <div
        v-if="showTextDetail"
        class="d-flex align-center"
      >
        <h3 class="text-2xl font-weight-semibold me-4">
          {{ statPercentage }}
        </h3>
        <span>{{ statDescription }}</span>
      </div>

      <v-btn
        v-if="showBtnDetail"
        block
        color="primary"
        class="mt-6"
        outlined
        @click="onClickBtnDetail"
      >
        {{ statDetailBtn }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
/* eslint-disable arrow-body-style */
import { mdiHelp, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'

// import { kFormatter } from '@core/utils/filter'
import { getVuetify } from '@core/utils'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    statTitle: { type: String, default: 'Weekly Overview' },
    statPercentage: { type: String, default: '45%' },
    statDescription: { type: String, default: 'Your sales perfomance in 45% 🤩 better compare to last month' },
    chartCategories: {
      type: Array,
      default: () => [
        { x: 1, y: 'L' },
        { x: 2, y: 'M' },
        { x: 3, y: 'M' },
        { x: 4, y: 'J' },
        { x: 5, y: 'V' },
        { x: 6, y: 'S' },
        { x: 7, y: 'D' },
      ],
    },
    chartData: {
      type: Array,
      default: () => [
        {
          data: [
            { x: '2019/01/01', y: 400 },
            { x: '2019/04/01', y: 430 },
            { x: '2019/07/01', y: 448 },
            { x: '2019/10/01', y: 470 },
            { x: '2020/01/01', y: 540 },
            { x: '2020/04/01', y: 580 },
            { x: '2020/07/01', y: 690 },
            { x: '2020/10/01', y: 690 },
          ],
        },
      ],
    },
    chartConfig: {
      type: Object,
      default: null,
    },
    chartColor: {
      type: Array,
      default: () => [
        '#f5f5f5',
        '#f5f5f5',
        '#f5f5f5',
        '#f5f5f5',
        '#f5f5f5',
        '#f5f5f5',
        '#f5f5f5',
      ],
    },
    chartHeight: {
      type: String,
      default: 'auto',
    },
    showBtnDetail: {
      type: Boolean,
      default: true,
    },
    statDetailBtn: { type: String, default: 'Details' },
    showTextDetail: {
      type: Boolean,
      default: true,
    },
    showBtnDots: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const chartBar = ref(null)
    const $vuetify = getVuetify()
    const customChartColor = '#f5f5f5'
    const defaultColors = [
      customChartColor,
      customChartColor,
      customChartColor,
      customChartColor,
      $vuetify.theme.currentTheme.secondary,
      customChartColor,
      customChartColor,
    ]

    const computedChartCategories = computed(() => props.chartCategories)

    const chartOptions = ref({
      chart: {
        type: 'bar',
        toolbar: {
          show: true,
          offsetY: -15,
          tools: {
            download: false,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
        },
        offsetX: -15,
        zoom: {
          enabled: true,
          type: 'x',
        },
      },
      plotOptions: {
        bar: {
          distributed: false,
          borderRadius: 8,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: computedChartCategories.value,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickPlacement: 'on',
        labels: {
          show: true,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        show: true,
        tickAmount: 4,
        labels: {
          offsetY: 3,
        },
      },
      stroke: {
        width: [2, 2],
      },
      grid: {
        strokeDashArray: 12,
        padding: {
          right: 0,
        },
      },
      tooltip: {
        theme: 'dark',
        x: {
          show: true,
          title: {
            formatter: () => {
              return ''
            },
          },
          formatter: () => {
            return ''
          },
        },
        y: {
          title: {
            formatter: () => {
              return ''
            },
          },
          formatter: () => {
            return ''
          },
        },
      },
    })

    const computedChartOptions = computed(() => {
      if (props.chartConfig === null) {
        const options = JSON.parse(JSON.stringify(chartOptions.value))

        return options
      }

      return props.chartConfig
    })

    const computedChartData = computed(() => props.chartData)

    // const chartData = [
    //   {
    //     data: [40, 60, 50, 60, 75, 60, 50, 65],
    //   },
    // ]

    const onClickBtnDetail = () => {
      emit('onClickBtnDetail', false)
    }

    return {
      chartBar,
      chartOptions,
      computedChartOptions,
      computedChartData,
      computedChartCategories,
      defaultColors,

      onClickBtnDetail,

      icons: {
        mdiHelp,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
